import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Боксерський клуб "Воїн"
			</title>
			<meta name={"description"} content={"Ваша подорож починається тут"} />
			<meta property={"og:title"} content={"Про нас | DreamHome Realty"} />
			<meta property={"og:description"} content={"Ваша подорож починається тут"} />
			<meta property={"og:image"} content={"https://techgrowthup.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://techgrowthup.com/img/6668733.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://techgrowthup.com/img/6668733.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://techgrowthup.com/img/6668733.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://techgrowthup.com/img/6668733.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://techgrowthup.com/img/6668733.png"} />
			<meta name={"msapplication-TileImage"} content={"https://techgrowthup.com/img/6668733.png"} />
			<meta name={"msapplication-TileColor"} content={"https://techgrowthup.com/img/6668733.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Про нас
				</Text>
				<Text font="--lead" color="--darkL2" max-width="600px">
				У DreamHome Realty ми віримо, що дім - це більше, ніж просто місце для життя, це місце, де створюються спогади та будується майбутнє. Наш шлях розпочався з бачення трансформації досвіду роботи з нерухомістю шляхом надання пріоритету потребам клієнтів та персоналізованого сервісу. З роками ми виросли, але залишаємося відданими досконалості. Ми пишаємося тим, що допомагаємо незліченним сім'ям знайти своє ідеальне житло.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://techgrowthup.com/img/3.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Наша команда
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Наша команда відданих професіоналів є наріжним каменем DreamHome Realty. Кожен член команди має багатий досвід та пристрасть до нерухомості. Від досвідчених агентів до кваліфікованого допоміжного персоналу, кожен член команди прагне надавати винятковий сервіс. Ми працюємо разом, щоб ваш досвід був бездоганним і приємним.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://techgrowthup.com/img/4.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Наші послуги
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						DreamHome Realty пропонує широкий спектр послуг. Незалежно від того, чи хочете ви купити, продати або орендувати нерухомість, ми маємо досвід, щоб допомогти вам. Наше портфоліо включає широкий спектр об'єктів нерухомості, від затишних квартир до розкішних маєтків. Ми використовуємо новітні технології та знання ринку, щоб надати найкращі варіанти та поради, роблячи процес купівлі-продажу нерухомості плавним і безтурботним.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://techgrowthup.com/img/5.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Приєднуйтесь до нашої спільноти
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Створіть свою історію з DreamHome Realty. Ми запрошуємо вас стати частиною нашої спільноти і відчути різницю, яку може зробити наш персоналізований, професійний сервіс. Наша команда прагне допомогти вам досягти ваших цілей у сфері нерухомості.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline3"
					lg-text-align="center"
					md-text-align="left"
				>
					DreamHome Realty - це більше, ніж агентство нерухомості, це місце, де ваші мрії набувають форми. Зв'яжіться з нами сьогодні і розпочніть свою подорож до ідеального будинку. Дозвольте нам допомогти вам перетворити ваші мрії про нерухомість на реальність.
				</Text>
				<Button align-self="center" href="/contacts" type="link" text-decoration-line="initial">
					Зв'яжіться з нами
				</Button>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://techgrowthup.com/img/6.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});